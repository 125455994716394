<template>
  <div class="vlive-popup" id="vlive_popup">
    <div
      class="popup-box"
      :class="radiusType === 'full' ? borderFull : radiusType === 'bottom' ? borderBottom : ''"
      :style="{ width, margin, height }"
      ref="popupBox"
    >
      <div class="popup-header" :class="radiusType === 'top' || radiusType === 'full' ? borderTop : ''">
        <img
          v-if="!remove && !showDarkClose"
          src="@/assets/icons/ClosePopup.svg"
          alt="closes"
          id="popup-close-icon"
          class="close-popup"
          @click="closePopupEvent()"
          :class="[localDisplayLang === 'ara' ? 'rev-close' : 'main-close']"
        />
        <img
          v-if="showDarkClose"
          class="close-popup"
          :class="[localDisplayLang === 'ara' ? 'rev-dark-close' : 'dark-close']"
          @click="closePopupEvent()"
          src="@/assets/icons/close_with_shadow.svg"
          alt
        />
        <slot name="header"></slot>
      </div>
      <div class="popup-social">
        <slot name="social"></slot>
      </div>
      <div
        class="popup-component"
        :class="radiusType === 'bottom' ? borderBottom : radiusType === 'full' ? borderFull : ''"
      >
        <slot name="component"></slot>
        <Loading class="loader" v-if="loader"></Loading>
      </div>
    </div>
  </div>
</template>

<script>
import { eventBus } from "@/eventBus";
import { mapGetters } from "vuex";

export default {
  name: "Popup",
  props: {
    closePopupEvent: {
      type: Function,
    },
    height: {
      type: String,
    },
    width: {
      type: String,
    },
    margin: {
      type: String,
    },
    radiusType: {
      type: String,
    },
    remove: {
      type: String,
    },
    authUser: {
      type: String,
    },
    showDarkClose: {
      type: Boolean,
    },
  },
  data() {
    return {
      borderTop: "border-top",
      borderBottom: "border-bottom",
      borderFull: "border-full",
      loader: false,
      localDisplayLang: null,
    };
  },
  watch: {
    $route() {
      this.closePopupEvent();
    },
  },
  computed: {
    ...mapGetters(["getRtl"]),
  },
  mounted() {
    // let self = this;
    // let isScroll = true;

    // setTimeout(() => {
    //    let popup = document.getElementById("vlive_popup");
    //     popup.addEventListener("scroll", function() {
    //       let scrollHeight;
    //       let elementScrollHeight;

    //       scrollHeight = document.body.scrollHeight;
    //       elementScrollHeight = popup.scrollTop;

    //       if (elementScrollHeight >= scrollHeight) {
    //         if (isScroll) {
    //           eventBus.$emit("loadMore");
    //           isScroll = false;
    //         }
    //       }
    //     });
    // }, 2000);

    document.body.style.overflow = "hidden";

    eventBus.$on("loader", (state) => {
      this.loader = state;
    });
  },
  created() {
    this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");

    //checking for change of language in local storage value
    eventBus.$on("check-local-storage", (data) => {
      this.localDisplayLang = data;
    });
  },
  methods: {
    getOffset(el) {
      const rect = el.getBoundingClientRect();
      return {
        left: rect.left + window.scrollX,
        top: rect.top + window.scrollY,
      };
    },
  },
  beforeDestroy() {
    document.body.style.overflow = "auto";
  },
  components: {
    Loading: () => import(/* webpackChunkName: "Loading" */ "@/components/Templates/Loading.vue"),
  },
};
</script>

<style lang="scss">
@import "@/sass/_variables.scss";
.border-full {
  border-radius: 0.6rem;
}
.border-top {
  border-top-left-radius: 0.6rem;
  border-top-right-radius: 0.6rem;
}
.border-bottom {
  border-bottom-left-radius: 0.6rem;
  border-bottom-right-radius: 0.6rem;
}
.vlive-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $clr-dark-hlt;
  z-index: 10;
  overflow: auto;
  .popup-box {
    background-color: $clr-dark-gd2;
    min-width: 320px;
    .popup-header {
      position: relative;
      .close-popup {
        width: 15px;
        position: absolute;
        top: 20px;
        z-index: 9;
        cursor: pointer;
      }
      .main-close {
        opacity: 0.4;
        right: 4%;
        &:hover {
          opacity: 0.8;
        }
      }
      .rev-close {
        opacity: 0.4;
        left: 4%;
        &:hover {
          opacity: 0.8;
        }
      }
      .dark-close {
        width: 30px;
        top: 10px;
        right: 10px;
      }
      .rev-dark-close {
        width: 30px;
        top: 10px;
        left: 10px;
      }
    }
    .popup-component {
      background-color: $clr-dark-gd2;
      position: relative;
    }
    .loader {
      position: absolute;
      bottom: 0;
      width: 100%;
      margin: 0 auto;
    }
  }
}

@media only screen and (max-width: 576px) {
  // .vlive-popup {
  // .popup-box{
  //   min-width: 260px;
  // }
  // }

  .vlive-popup {
    margin: 3rem 0;
  }
}
</style>
